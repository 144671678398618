import { template as template_3599f071a13a43619c87d8495d4541fd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3599f071a13a43619c87d8495d4541fd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
