import { template as template_10d95ddbe9f84dcf985dd022d5303147 } from "@ember/template-compiler";
const SidebarSectionMessage = template_10d95ddbe9f84dcf985dd022d5303147(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
