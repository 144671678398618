import { template as template_c96e3056cec34fd38c0db10173862cda } from "@ember/template-compiler";
const FKLabel = template_c96e3056cec34fd38c0db10173862cda(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
