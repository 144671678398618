import { template as template_0dc7222a7e58409d88e1e6c5684d51dc } from "@ember/template-compiler";
const WelcomeHeader = template_0dc7222a7e58409d88e1e6c5684d51dc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
